<template>
  <v-card dark width="200px"  class="rounded-card"  outlined >
      <v-card-title>{{ title }}</v-card-title>
    <!-- <div :style="[{ fontSize: 0.12*tileHeight + 'px' }, {marginBottom: tileHeight/6 + 'px'}]" >{{ title }}</div> -->
    <v-card-text>{{ text }}</v-card-text>
    <!-- <div :style="{ fontSize: 0.08*tileHeight + 'px' }"></div> -->
  </v-card>
</template>
<script>
export default {
  props: {
    title: String,
    text: String,
    tileHeight: Number,
  },
};
</script>
<style scoped>
.rounded-card {
  border-radius: 4px;
}
</style>
