<template>
  <!-- style="margin-top: 4px" -->
  <v-navigation-drawer
    :mini-variant="true"
    :clipped="true"
    v-model="drawer"
    app
    mini-variant-width="100"
    class="secondary lighten-1"
  >
    <v-list nav three-line>
      <NavigationTile></NavigationTile>

      <v-divider class="mx-3"></v-divider>

      <v-tooltip right color="secondary">
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on" @click="openAddTiles()" class="tile-cat-1">
            <v-list-item-action style="align-self: unset">
              <v-icon>mdi-plus</v-icon>
            </v-list-item-action>
            <!-- workaround -->
            <v-list-item-content></v-list-item-content>
          </v-list-item>
        </template>
        <span>add tiles</span>
      </v-tooltip>

      <v-tooltip right color="secondary">
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on" @click="openChangeVisuals()" class="tile-cat-1">
            <v-list-item-action style="align-self: unset">
              <v-icon>mdi-palette</v-icon>
            </v-list-item-action>
            <!-- workaround -->
            <v-list-item-content></v-list-item-content>
          </v-list-item>
        </template>
        <span>change visuals</span>
      </v-tooltip>

      <v-tooltip right color="secondary">
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on" @click="deleteTiles()" class="tile-cat-1">
            <v-list-item-action style="align-self: unset">
              <v-icon>mdi-delete</v-icon>
            </v-list-item-action>
            <!-- workaround -->
            <v-list-item-content></v-list-item-content>
          </v-list-item>
        </template>

        <span>delete all tiles</span>
      </v-tooltip>

      <v-divider class="mx-3"></v-divider>

      <v-tooltip v-if="$vuetify.breakpoint.smAndUp" right color="secondary">
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on" @click="toggleFullscreen()">
            <v-list-item-action style="align-self: unset">
              <v-icon>mdi-fullscreen</v-icon>
            </v-list-item-action>
            <!-- workaround -->
            <v-list-item-content></v-list-item-content>
          </v-list-item>
        </template>

        <span>toggle fullscreen</span>
      </v-tooltip>

      <v-tooltip right color="secondary">
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on" @click="share()">
            <v-list-item-action style="align-self: unset">
              <v-icon>mdi-share-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content></v-list-item-content>
          </v-list-item>
        </template>

        <span>share</span>
      </v-tooltip>

      <v-tooltip right color="secondary">
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on" @click="resetApp()">
            <v-list-item-action style="align-self: unset">
              <v-icon>mdi-backup-restore</v-icon>
            </v-list-item-action>
            <v-list-item-content></v-list-item-content>
          </v-list-item>
        </template>

        <span>reset app</span>
      </v-tooltip>
    </v-list>

    <template v-slot:append v-if="$vuetify.breakpoint.lgAndUp">
      <v-list nav two-line>
        <v-list-item @click="$store.commit('setRightDrawer', false)">
          <v-list-item-action style="align-self: unset">
            <v-icon>mdi-close</v-icon>
          </v-list-item-action>
          <!-- workaround -->
          <v-list-item-content></v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>
<script>
import NavigationTile from "./NavigationTile";

export default {
  data() {
    return {
      clipped: false,
    };
  },
  computed: {
    drawer: {
      get() {
        return this.$store.getters.rightDrawer;
      },
      set(val) {
        this.$store.commit("setRightDrawer", val);
      },
    },
  },
  methods: {
    toggleFullscreen() {
      this.$ga.event({
        eventCategory: "menu",
        eventAction: "toggle_fullscreen",
      });

      var doc = window.document;
      var docEl = doc.documentElement;

      var requestFullScreen =
        docEl.requestFullscreen ||
        docEl.mozRequestFullScreen ||
        docEl.webkitRequestFullScreen ||
        docEl.msRequestFullscreen;
      var cancelFullScreen =
        doc.exitFullscreen ||
        doc.mozCancelFullScreen ||
        doc.webkitExitFullscreen ||
        doc.msExitFullscreen;

      if (
        !doc.fullscreenElement &&
        !doc.mozFullScreenElement &&
        !doc.webkitFullscreenElement &&
        !doc.msFullscreenElement
      ) {
        requestFullScreen.call(docEl);
      } else {
        cancelFullScreen.call(doc);
      }
    },
    deleteTiles() {
      this.$store.commit("setTiles", []);
    },
    resetApp() {
      this.$ga.event({
        eventCategory: "menu",
        eventAction: "reset_app",
        //eventLabel: 'label',
        //eventValue: 123
      });

      this.$store.commit("resetState", this.$store.getters.storageVersion);
      // localStorage.clear();
      location.reload();
    },
    openChangeVisuals() {
      this.$ga.event({
        eventCategory: "menu",
        eventAction: "open_change_visuals",
        //eventLabel: 'label',
        //eventValue: 123
      });

      this.$store.commit("setShowChangeVisualsDialog", true);
    },
    share() {
      this.$ga.event({
        eventCategory: "menu",
        eventAction: "share",
        eventLabel: "navigator.share",
        eventValue: navigator.share,
      });

      this.$store.commit("setShowShareDialog", true);
    },
    openAddTiles() {
      this.$ga.event({
        eventCategory: "menu",
        eventAction: "open_add_tiles",
        //eventLabel: 'label',
        //eventValue: 123
      });

      this.$store.commit("setShowAddTilesDialog", true);
    },
  },
  components: {
    NavigationTile,
  },
};
</script>
<style scoped></style>
