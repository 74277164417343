<template>
  <v-container fill-height>
    <v-row>
      <v-col align="center">
        <v-icon
          color="secondary darken-1"
          style="margin-bottom: 20px; font-size: 60px"
          >mdi-dashboard</v-icon
        >
        <div
          v-if="$store.getters.storageHasBeenUpdatedSincePreviousVisit"
          style="margin-bottom: 60px"
        >
          <h2 class="grey--text headline" style="margin-bottom: 10px">
            cryptodash has been updated.
          </h2>
          <h2 class="grey--text title">
            all settings have been cleared. sorry for the inconvenience
          </h2>
        </div>
        <h2 class="grey--text grey--text display-1" style="margin-bottom: 10px">
          add cryptocurrency tiles through the menu on the right
        </h2>
        <h2 class="white--text title" style="margin-bottom: 10px">
          or choose a template
        </h2>
        <v-btn large color="primary" @click="addTopCoins()"
          >add top coins</v-btn
        >
        <v-btn
          large
          color="primary"
          class="ma-4"
          disabled
          @click="addTopCoins()"
          >Ethereum + DEFI</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      showFab: false,
    };
  },
  methods: {
    addTopCoins() {
      this.$ga.event({
        eventCategory: "no_tiles",
        eventAction: "add_top_coins",
        //eventLabel: 'label',
        //eventValue: 123
      });

      this.$store.commit("resetTickProgress");
      this.$store.dispatch("populateDashboardWithTopAvailableCoins");
    },
  },
  components: {},
};
</script>
