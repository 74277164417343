<template>
  <v-bottom-sheet v-model="sheet" :inset="$vuetify.breakpoint.mdAndUp">
    <v-card height="90vh">
      <v-toolbar flat tabs color="secondary darken-1">
        <v-spacer></v-spacer>
        <v-toolbar-title centered>add tiles</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click.native="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <CoinPrice />
    </v-card>
  </v-bottom-sheet>
</template>
<script>
import CoinPrice from "./CoinPrice";
import MarketOverview from "./MarketOverview";

export default {
  data() {
    return {
      active_tab: 0,
      tabs: [
        { name: "coins", disabled: false },
        { name: "market overview", disabled: true },
        { name: "defi", disabled: true },
        { name: "ENS", disabled: true },
        { name: "staking", disabled: true },
        { name: "portfolio", disabled: true },
      ],
    };
  },
  computed: {
    sheet: {
      get() {
        return this.$store.getters.showAddTilesDialog;
      },
      set(val) {
        this.$store.commit("setShowAddTilesDialog", val);
      },
    },
  },

  methods: {
    closeDialog() {
      this.$ga.event({
        eventCategory: "menu",
        eventAction: "close_add_tiles",
        //eventLabel: 'label',
        //eventValue: 123
      });

      this.$store.commit("setShowAddTilesDialog", false);
    },
  },
  components: {
    CoinPrice,
    MarketOverview,
  },
};
</script>
<style scoped>
.v-tab--active {
  color: white !important;
}
</style>
