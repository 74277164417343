<template>
  <v-card class="mx-auto">
    <LineTile
      key="1"
      :tileId="asset.symbol + 'tile'"
      :title="asset.symbol + 'USD'"
      :time="selectedTimespan.name"
      :tileHeight="200"
      :tileWidth="300"
      :mouseOver="mouseOver"
    >
      <template v-slot:timespan>
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="rgba(0, 0, 0, 0.7)"
              class="pa-0 text-none"
              v-on="on"
            >
              {{ selectedTimespan.name }}
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in $store.getters.timespans"
              :key="index"
              @click="selectedTimespan = item"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:actions>
        <v-spacer />
        <v-btn small color="rgba(0, 0, 0, 0.7)" @click="leggTilPriceTile()"
          >add tile</v-btn
        >
        <v-spacer />
      </template>
    </LineTile>
  </v-card>
</template>
<script>
import { v4 as uuidv4 } from "uuid";

import LineTile from "../../../components/tiles/LineTile";

export default {
  props: {
    mouseOver: Boolean,
    asset: Object,
  },
  data() {
    return {
      selectedTimespan: this.$store.getters.timespans[1],
    };
  },
  computed: {},
  methods: {
    async leggTilPriceTile() {
      this.$ga.event({
        eventCategory: "tiles",
        eventAction: "add_tile",
        eventLabel: this.asset.symbol + "_" + this.selectedTimespan.name,
        //eventValue: 123
      });

      this.$store.dispatch("addTileAndGetTickersAndSaveToStore", {
        tile: {
          asset: {
            id: this.asset.id,
            symbol: this.asset.symbol + "USD",
            title: this.asset.name,
          },
          timespanName: this.selectedTimespan.name,
          tickers: [],
          h: 1,
          i: uuidv4(),
          w: 1,
          x: 0,
          y: 0,
        },
        timespan: this.selectedTimespan,
      });
    },
  },
  components: {
    LineTile,
  },
};
</script>
