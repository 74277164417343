<template>
  <transition appear name="bounce" mode="out-in">
    <v-card
      v-if="dataPoints && dataPoints.length"
      @mouseover="mouseOver = true"
      @mouseleave="mouseOver = false"
    >
      <LineTile
        key="1"
        :tileId="tile.i"
        :title="tile.asset.symbol"
        :time="tile.timespanName"
        :tileHeight="height"
        :tileWidth="width"
        :dataPoints="dataPoints"
        :mouseOver="mouseOver"
      >
        <!-- <template v-slot:titleAction>
          <v-btn text small style="color: rgba(0, 0, 0, 0.7);" class="pa-0 ma-0">
            <h2
              class="subtitle-2 text-uppercase"
              style="color: rgba(0, 0, 0, 0.7);"
            >{{tile.asset.symbol}}</h2>
            <v-icon style="color: rgba(0, 0, 0, 0.7);">mdi-menu-down</v-icon>
          </v-btn>
        </template> -->
        <template v-slot:actions>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <a href="https://www.coingecko.com/" target="_blank" v-on="on">
                <img src="@/assets/coingecko.png" />
              </a>
            </template>
            <span>powered by CoinGecko</span>
          </v-tooltip>
          <v-spacer />
          <v-btn small text color="secondary" @click="deleteTile()"
            >delete</v-btn
          >
        </template>
      </LineTile>
    </v-card>
    <v-card v-else key="2" flat class="loading" align="center" :height="height">
      <v-progress-circular
        :style="{ marginTop: (height - loadingSize) / 2 + 'px' }"
        indeterminate
        :size="loadingSize"
        color="primary"
      ></v-progress-circular>
    </v-card>
  </transition>
</template>
<script>
import LineTile from "./LineTile";

export default {
  props: {
    tile: Object,
    height: Number,
    width: Number,
  },

  data() {
    return {
      mouseOver: false,
      loadingSize: 70,
    };
  },
  methods: {
    deleteTile() {
      this.$ga.event({
        eventCategory: "tile",
        eventAction: "delete",
        //eventLabel: 'label',
        //eventValue: 123
      });
      this.$store.commit("deleteTile", { i: this.tile.i });
    },
    shareTile() {
      this.$ga.event({
        eventCategory: "tile",
        eventAction: "share",
        //eventLabel: 'label',
        //eventValue: 123
      });

      this.$store.commit("setSnackbar", {
        show: true,
        text: "coming soon!",
      });
    },
  },
  computed: {
    dataPoints() {
      var tickers = this.tile.tickers;
      if (!tickers) return [];

      var dp = tickers.map((ticker) => {
        return {
          x: ticker.timestamp,
          y: ticker.price,
        };
      });

      return dp;
    },
  },
  components: { LineTile },
};
</script>
<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  width: 100%;
}

.loading {
  background-color: rgba(0, 0, 0, 0.07) !important;
  border-radius: 4px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.flip-enter-active {
  transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.flip-leave-active {
  transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.flip-enter,
.flip-leave-to {
  transform: scaleX(0) translateZ(0);
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.35s reverse;
}

.bounce-enter,
.bounce-leave-to {
}

@keyframes bounce-in {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
