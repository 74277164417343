<template>
    <v-container fluid style="padding: 0px;">
        <v-col style="padding: 0px;" v-resize="onResize">
            <!-- :responsive="true" :cols="{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }" -->
            <grid-layout
                v-if="tilesRetrieved"
                :layout.sync="localLayout"
                :col-num="numberOfColumns"
                :row-height="rowHeight"
                :is-draggable="true"
                :is-resizable="false"
                :is-mirrored="false"
                :margin="[10, 10]"
                :use-css-transforms="true"
            >
                <grid-item
                    v-for="item in localLayout"
                    :key="item.i"
                    :i="item.i"
                    :x="item.x"
                    :y="item.y"
                    :w="item.w"
                    :h="item.h"
                    :isDraggable="item.asset ? true : false"
                    :isResizable="false"
                    @moved="movedEvent"
                    @resized="resizedEvent"
                >
                    <ApiLineTile
                        v-if="item.asset"
                        :key="item.i"
                        :tile="item"
                        :height="tileHeight(item)"
                        :width="tileWidth(item)"
                    ></ApiLineTile>
                </grid-item>
            </grid-layout>
        </v-col>
    </v-container>
</template>

<script>
import VueGridLayout from "vue-grid-layout";

// import ImageTile from "./tiles/ImageTile"
import ApiLineTile from "./tiles/ApiLineTile";

import grid from "../grid";

export default {
    data() {
        return {
            windowHeight: null,
            windowWidth: null,
            localLayout: [],
            tilesRetrieved: false,
        };
    },
    watch: {
        tiles: function(newVal) {
            // oldVal
            this.tilesRetrieved = true;
            this.localLayout = newVal;
        },
    },
    mounted() {
        let tiles = this.tiles;

        if (tiles && tiles.length) {
            this.tilesRetrieved = true;
            this.localLayout = tiles;
        }

        this.onResize();
    },
    methods: {
        onResize() {
            this.windowHeight = window.innerHeight;
            this.windowWidth = window.innerWidth;
        },
        movedEvent: function(i, newX, newY) {
            this.$store.commit("moveTile", { i: i, x: newX, y: newY });
        },
        resizedEvent: function(i, newH, newW) {
            // newHPx, newWPx
            this.$store.commit("resizeTile", { i: i, h: newH, w: newW });
        },
        tileHeight(tile) {
            if (tile.h === 1) {
                return this.rowHeight;
            } else {
                return this.rowHeight * tile.h + tile.h * 5;
            }
        },
        tileWidth(tile) {
            if (tile.w === 1) {
                return this.columnWidth;
            } else {
                return this.columnWidth * tile.w + tile.w * 5;
            }
        },
    },
    computed: {
        numberOfColumns() {
            return grid.calculateNumberOfColumns(this.windowWidth);
        },
        numberOfRows() {
            return grid.calculateNumberOfRows(this.windowHeight);
        },
        tiles() {
            return this.$store.getters.tiles.filter((tile) => {
                return tile.x + tile.w - 1 < this.numberOfColumns;
            });
        },
        rowHeight() {
            return (
                (this.windowHeight - this.numberOfRows * 10 - 10) /
                this.numberOfRows
            );
        },
        columnWidth() {
            return (
                this.windowWidth / this.numberOfColumns -
                2 * this.numberOfColumns
            );
        },
    },
    components: {
        // ImageTile,
        GridLayout: VueGridLayout.GridLayout,
        GridItem: VueGridLayout.GridItem,
        ApiLineTile,
    },
};
</script>

<style>
.vue-grid-layout {
    width: 100%;
}

.vue-grid-item {
    background-color: transparent;
}

.vue-grid-item.vue-grid-placeholder {
    background-color: rgba(0, 0, 0, 0.4) !important;
    border-radius: 4px;
}

.vue-resizable-handle {
    background: transparent !important;
}

.bounce-enter-active {
    animation: bounce-in 0.5s;
}
.bounce-leave-active {
    animation: bounce-in 0.35s reverse;
}

.bounce-enter,
.bounce-leave-to {
}

@keyframes bounce-in {
    0% {
        transform: scale(0.7);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
</style>
