<template>
  <v-container class="pl-10 pr-10">
    <template v-for="(asset, index) in search">
      <v-row :key="index + 'h'">
        <v-img :src="asset.image" max-width="40px" max-height="40px"></v-img>
        <h3 style="line-height: 40px; margin-left: 10px">{{ asset.name }}</h3>
      </v-row>
      <v-row :key="index" class="mb-4">
        <v-col
          v-for="(tileType, index) in generalTiles"
          :key="index"
          cols="12"
          sm="4"
          lg="3"
        >
          <v-hover>
            <template v-slot:default="{ hover }">
              <AddableLineTile
                :asset="asset"
                :mouseOver="hover"
              ></AddableLineTile>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>
<script>
import AddableLineTile from "./AddableLineTile";

export default {
  props: {
    search: Array,
  },
  data() {
    return {
      sheet: null,
      generalTiles: [1],
    };
  },
  components: {
    AddableLineTile,
  },
};
</script>
