import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import vuetify from "./plugins/vuetify";

import "./plugins/vue-analytics";
import "./plugins/currency-filter";

import controller from "./controller";

Vue.config.productionTip = false;

const currentStorageVersion = 2;

controller
  .init(currentStorageVersion)
  .then(res => console.log(res))
  .catch(err => console.log(err));

new Vue({
  store,
  vuetify,
  render: h => h(App),
}).$mount("#app");
