<template>
  <v-card color="secondary" flat class="text-center" height="100vh">
    <v-container>
      <v-row>
        <v-col>
          <TextTile title="ETH market dominance" :tileHeight="400" text="50%" />
        </v-col>
        <v-col>
          <TextTile
            title="market_cap_change_percentage_24h_usd"
            :tileHeight="400"
            text="+2%%"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import TextTile from "../../tiles/TextTile";
export default {
  components: {
    TextTile,
  },
};
</script>
