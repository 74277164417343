import axios from "axios";
import { ASAP } from "downsample";

const instance = axios.create({
    baseURL: "https://api.coingecko.com/api/v3",
    headers: typeof window !== "undefined" ? {} : { "User-Agent": "yoyo" },
    timeout: 30000,
});

function scheduleRequests(axiosInstance, intervalMs) {
    let lastInvocationTime = undefined;

    const scheduler = (config) => {
        const now = Date.now();
        if (lastInvocationTime) {
            lastInvocationTime += intervalMs;
            const waitPeriodForThisRequest = lastInvocationTime - now;
            if (waitPeriodForThisRequest > 0) {
                return new Promise((resolve) => {
                    setTimeout(() => resolve(config), waitPeriodForThisRequest);
                });
            }
        }

        lastInvocationTime = now;
        return config;
    };

    axiosInstance.interceptors.request.use(scheduler);
}

scheduleRequests(instance, 500);

async function request(url, params = {}) {
    return new Promise((resolve, reject) => {
        instance
            .get(url, { params })
            .then(function(response) {
                resolve(response.data);
            })
            .catch(function(error) {
                if (error.response) console.warn(error.response);
                reject(error.response.status);
                // throw error.message;
            });
    });
}

async function getAvailableCoins() {
    const params = {
        vs_currency: "usd",
        order: "market_cap_desc",
        per_page: 1000,
        page: 1,
        sparkline: false,
    };
    return request("/coins/markets", params).then((res) => {
        return res;
    });
}

async function getHistoricalTickersForCoin(id, from) {
    // , params = { end: false, interval: "5m", quote: "USD", limit: 5000 }
    const queryParams = {
        from: from,
        to: Math.floor(Date.now() / 1000),
        vs_currency: "usd",
    };
    const asd = request(`/coins/${id}/market_chart/range`, queryParams).then(
        (res) => {
            const arrayOfArrays = res.prices;

            //printStuff(arrayOfArrays);

            return ASAP(arrayOfArrays, 50).map((el) => {
                return {
                    timestamp: el.x,
                    price: el.y,
                };
            });
        }
    );
    return asd;
}
/*
function printStuff(arrayOfArrays) {
    console.log("total elements 7d", arrayOfArrays.length);

    var oneHAgo = new Date(new Date().getTime() - 1 * 60 * 60 * 1000);
    var twentyFourHAgo = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
    var oneWeekAgo = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    // var oneMonthAgo = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
    // var oneYearAgo = new Date(new Date().getTime() - (365 * 24 * 60 * 60 * 1000));

    console.log("----- last 1h -----");
    const elementerSiste1h = arrayOfArrays.filter((el) => el[0] > oneHAgo);
    console.log(
        "----- items after 24h ago",
        elementerSiste1h.length
    );
    console.log("-----------");

    console.log("----- last 24h -----");
    const elementerSiste24h = arrayOfArrays.filter(
        (el) => el[0] > twentyFourHAgo
    );
    console.log(
        "----- items 24h ago",
        elementerSiste24h.length
    );
    console.log("-----------");

    console.log("----- last 7d -----");
    const elementerSisteUke = arrayOfArrays.filter((el) => el[0] > oneWeekAgo);
    console.log(
        "----- items after for 7d ago",
        elementerSisteUke.length
    );
    console.log("-----------");
}
*/
async function getTickersForAllCoins(params = { vs_currency: "usd" }) {
    return request("/coins/markets", params);
}

export default {
    getAvailableCoins,
    getTickersForAllCoins,
    getHistoricalTickersForCoin,
};
