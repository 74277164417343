<template>
  <svg
    :viewBox="'0 0 ' + width + ' ' + height"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    class="svg-path"
    :height="height"
    width="100%"
    preserveAspectRatio="none"
  >
    <path id="area" stroke="none" :d="areaPath" />
    <path id="line" fill="none" stroke="grey" :d="linePath" />
  </svg>
</template>
<script>
// https://medium.com/@francoisromain/smooth-a-svg-path-with-cubic-bezier-curves-e37b49d46c74
// https://codepen.io/francoisromain/pen/dzoZZj
const map = (value, inMin, inMax, outMin, outMax) => {
  return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
};

const pointsPositionsCalc = (points, w, h, options) =>
  points.map((e) => {
    const x = map(e.x, options.xMin, options.xMax, 0, w);
    const y = map(e.y, options.yMin, options.yMax, h, 0);
    return [x, y];
  });

// Properties of a line
// I:  - pointA (array) [x,y]: coordinates
//     - pointB (array) [x,y]: coordinates
// O:  - (object) { length: l, angle: a }: properties of the line
const line = (pointA, pointB) => {
  const lengthX = pointB[0] - pointA[0];
  const lengthY = pointB[1] - pointA[1];
  return {
    length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
    angle: Math.atan2(lengthY, lengthX),
  };
};

// Position of a control point
// I:  - current (array) [x, y]: current point coordinates
//     - previous (array) [x, y]: previous point coordinates
//     - next (array) [x, y]: next point coordinates
//     - reverse (boolean, optional): sets the direction
// O:  - (array) [x,y]: a tuple of coordinates
const controlPoint = (current, previous, next, reverse) => {
  // When 'current' is the first or last point of the array
  // 'previous' or 'next' don't exist.
  // Replace with 'current'
  const p = previous || current;
  const n = next || current;

  // Properties of the opposed-line
  const o = line(p, n);

  // If is end-control-point, add PI to the angle to go backward
  const angle = o.angle + (reverse ? Math.PI : 0);
  const length = o.length * 0.15;

  // The control point position is relative to the current point
  const x = current[0] + Math.cos(angle) * length;
  const y = current[1] + Math.sin(angle) * length;
  return [x, y];
};

// Create the bezier curve command
// I:  - point (array) [x,y]: current point coordinates
//     - i (integer): index of 'point' in the array 'a'
//     - a (array): complete array of points coordinates
// O:  - (string) 'C x2,y2 x1,y1 x,y': SVG cubic bezier C command
const bezierCommand = (point, i, a) => {
  // start control point
  const cps = controlPoint(a[i - 1], a[i - 2], point);

  // end control point
  const cpe = controlPoint(point, a[i - 1], a[i + 1], true);
  return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}`;
};

// Render the svg <path> element
// I:  - points (array): points coordinates
//     - command (function)
//       I:  - point (array) [x,y]: current point coordinates
//           - i (integer): index of 'point' in the array 'a'
//           - a (array): complete array of points coordinates
//       O:  - (string) a svg path command
// O:  - (string): a Svg <path> element
const svgAreaPath = (points, command, h) => {
  // build the d attributes by looping over the points
  const d = points.reduce(
    (acc, e, i, a) =>
      i === 0
        ? `M ${a[a.length - 1][0]},${h} L ${e[0]},${h} L ${e[0]},${e[1]}`
        : `${acc} ${command(e, i, a)}`,
    ""
  );

  return d;
};

const svgLinePath = (points, command) => {
  // build the d attributes by looping over the points
  const d = points.reduce(
    (acc, point, i, a) =>
      i === 0 ? `M ${point[0]},${point[1]}` : `${acc} ${command(point, i, a)}`,
    ""
  );
  return d;
};

export default {
  props: {
    height: Number,
    width: Number,
    points: {
      type: Array,
      default() {
        return [
          {
            x: 1500000800, // 0101
            y: 10,
          },
          {
            x: 1510000800, // idag
            y: 25,
          },
          {
            x: 1520000800, // idag
            y: 20,
          },
          {
            x: 1530000800, // idag
            y: 30,
          },
        ];
      },
    },
  },
  data() {
    return {
      smoothing: 0.15,
    };
  },
  computed: {
    options() {
      const yMinPoint = Math.min.apply(
        Math,
        this.points.map(function(o) {
          return o.y;
        })
      );
      const yMaxPoint = Math.max.apply(
        Math,
        this.points.map(function(o) {
          return o.y;
        })
      );
      return {
        yMin: yMinPoint - yMinPoint * (3 / 100),
        yMax: yMaxPoint + yMaxPoint * (3 / 100),
        xMin: Math.min.apply(
          Math,
          this.points.map(function(o) {
            return o.x;
          })
        ),
        xMax: Math.max.apply(
          Math,
          this.points.map(function(o) {
            return o.x;
          })
        ),
      };
    },
    areaPath() {
      const pointsPositions = pointsPositionsCalc(
        this.points,
        this.width,
        this.height,
        this.options
      );
      return svgAreaPath(pointsPositions, bezierCommand, this.height);
    },
    linePath() {
      const pointsPositions = pointsPositionsCalc(
        this.points,
        this.width,
        this.height,
        this.options
      );
      return svgLinePath(pointsPositions, bezierCommand);
    },
  },
};
</script>
<style scoped>
#area {
  fill: black;
  stroke: none;
  fill-opacity: 0.1;
}

#line {
  fill: none;
  stroke: black;
  stroke-opacity: 0.6;
  stroke-width: 2.5;
}
</style>
