<template>
    <v-bottom-sheet
        v-model="sheet"
        :inset="$vuetify.breakpoint.mdAndUp"
        hide-overlay
        max-width="500px"
    >
        <v-card color="secondary">
            <!--height="100vh"-->
            <v-toolbar dark flat color="secondary darken-1">
                <!--darken-1-->
                <v-btn icon disabled :large="$vuetify.breakpoint.smAndUp" dark></v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title centered>Share Coin Board</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon large dark @click.native="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-row>
                <v-col align="center">
                    <v-btn
                        fab
                        large
                        width="70"
                        height="70"
                        color="#4267B2"
                        class="ma-4 elevation-0"
                        @click="openInWindow(facebookShareUrl)"
                    >
                        <v-icon size="40" color="white">mdi-facebook</v-icon>
                    </v-btn>
                    <v-btn
                        fab
                        large
                        width="70"
                        height="70"
                        color="#00C6FF"
                        class="ma-4 elevation-0"
                        @click="openInWindow(twitterShareUrl)"
                    >
                        <v-icon size="40" color="white">mdi-twitter</v-icon>
                    </v-btn>
                    <v-btn
                        fab
                        large
                        width="70"
                        height="70"
                        color="#2867B2"
                        class="ma-4 elevation-0"
                        @click="openInWindow(linkedinShareUrl)"
                    >
                        <v-icon size="40" color="white">mdi-linkedin</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-bottom-sheet>
</template>
<script>
export default {
    data() {
        return {
            facebookShareUrl: encodeURI(
                "https://www.facebook.com/sharer.php?u=" + window.location.href
            ),
            twitterShareUrl: encodeURI(
                "https://twitter.com/intent/tweet?url=" + window.location.href
            ),
            linkedinShareUrl: encodeURI(
                "https://www.linkedin.com/shareArticle?mini=true&url=" + window.location.href
            ),
        };
    },
    computed: {
        sheet: {
            get() {
                return this.$store.getters.showShareDialog;
            },
            set(val) {
                this.$store.commit("setShowShareDialog", val);
            },
        },
    },
    methods: {
        openInWindow(url) {
            var left = (screen.width - 570) / 2;
            var top = (screen.height - 570) / 2;
            var params =
                "menubar=no,toolbar=no,status=no,width=570,height=570,top=" + top + ",left=" + left;
            window.open(url, "NewWindow", params);
        },
        closeDialog() {
            this.$store.commit("setShowShareDialog", false);
        },
    },
};
</script>
