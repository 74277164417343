import store from "./store";

async function init(currentStorageVersion) {
    if (store.getters.storageVersion !== currentStorageVersion) {
        store.commit("resetState", currentStorageVersion);
        store.commit("setStorageHasBeenUpdatedSincePreviousVisit", true);
        store.commit("setSnackbar", {
            text: "cryptodash has been updated",
            show: true,
        });
    } else {
        store.commit("setTheme", store.getters.theme);
    }
    return Promise.resolve("success");
}

function startTickerFetcher() {
    store.commit("resetTickProgress");

    setInterval(() => {
        tick().then(console.log);
        store.commit("resetTickProgress");
    }, store.getters.tickFrequencyMs);
    setInterval(() => {
        store.commit("incrementTickProgress");
    }, store.getters.tickProgressIncrementFrequencyMs);
}

async function tick() {
    console.log("TICK START");
    store.dispatch("getLatestTickersForTilesInStoreAndWriteToStore");
    store.commit("removeOldTickers");

    return Promise.resolve("tick success");
}

export default {
    init,
    startTickerFetcher,
};
