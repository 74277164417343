<template>
  <v-app
    dark
    :style="'background-color:' + $vuetify.theme.themes.dark.secondary">

    <v-main>
      <MyDashboard></MyDashboard>
    </v-main>

    <v-fab-transition>
      <v-btn
        id="menu-fab"
        color="secondary darken-1"
        fab
        fixed
        bottom
        left
        v-show="!$store.getters.rightDrawer"
        @click="fabClick"
      >
        <v-icon color="secondary lighten-2">mdi-cog</v-icon>
      </v-btn>
    </v-fab-transition>

    <AddTilesDialog class="secondary lighten-1"></AddTilesDialog>
    <ShareDialog class="secondary lighten-1"></ShareDialog>
    <ChangeVisualsBottomSheet></ChangeVisualsBottomSheet>
    <EditDrawer></EditDrawer>

    <v-snackbar
      v-model="$store.getters.snackbar.show"
      :bottom="true"
      :right="true"
      :timeout="5000"
    >
      {{ $store.getters.snackbar.text }}
      <v-btn
        color="primary"
        text
        @click="$store.commit('setSnackbar', { show: false })"
        >close</v-btn
      >
    </v-snackbar>
  </v-app>
</template>

<script>
import AddTilesDialog from "./components/settings/add-tiles/AddTilesDialog";
import ShareDialog from "./components/DashboardShareBottomSheet";
import ChangeVisualsBottomSheet from "./components/settings/ChangeVisualsBottomSheet";
import EditDrawer from "./components/settings/drawer/EditDrawer";
import MyDashboard from "./components/MyDashboard";

export default {
  data() {
    return {};
  },
  mounted() {
    this.$ga.page({
      page: "/",
      title: "Dashboard",
      location: window.location.href,
    });
  },
  methods: {
    fabClick: function() {
      this.$store.commit("setRightDrawer", !this.$store.getters.rightDrawer);
    },
  },
  components: {
    AddTilesDialog,
    ShareDialog,
    ChangeVisualsBottomSheet,
    EditDrawer,
    MyDashboard,
  },
};
</script>
<style>
html {
  overflow-y: hidden;
}

.icon-cat1.v-btn.v-btn--icon.theme--dark > div > i {
  color: var(--v-secondary-lighten4);
}

.icon-cat1:hover.v-btn.v-btn--icon.theme--dark > div > i {
  color: var(--v-primary-base);
}

.icon-cat2.v-btn.v-btn--icon.theme--dark > div > i {
  color: var(--v-secondary-lighten2);
}

.icon-cat2:hover.v-btn.v-btn--icon.theme--dark > div > i {
  color: var(--v-primary-base);
}
</style>
