<template>
  <v-card
    dark
    :color="cardColor"
    class
    :height="tileHeight"
    :class="[{ 'elevation-10': true }, { 'zooming animated': animated }]"
    @animationend="animated = false"
    style="color: rgba(0, 0, 0, 0.6);"
  >
    <v-toolbar flat dense :class="toolbarClass">
      <h2
        v-if="!$slots.title"
        class="subtitle-2 text-uppercase"
        style="color: rgba(0, 0, 0, 0.7);"
      >
        {{ title }}
      </h2>
      <slot v-else name="title"></slot>
      <v-spacer></v-spacer>
      <h2
        v-if="!$slots.timespan"
        class="subtitle-2"
        style="color: rgba(0, 0, 0, 0.7);"
      >
        {{ time }}
      </h2>
      <slot v-else name="timespan"></slot>
    </v-toolbar>
    <div :style="'height:' + chartHeight + 'px'">
      <SvgLineChart
        :points="dataPoints"
        :height="chartHeight"
        :width="tileWidth"
      ></SvgLineChart>
    </div>

    <v-col
      :style="[
        { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
        { height: priceHeight + 'px' },
      ]"
      class="pa-0"
    >
      <div class="text-center">
        <div :class="textClass" :style="{ fontSize: 0.16 * tileHeight + 'px' }">
          {{
            currentPrice
              | currency({
                fractionCount: fractionCount,
                fractionSeparator: $store.getters.decimalSeparator,
                thousandsSeparator: $store.getters.thousandsSeparator,
              })
          }}
        </div>
      </div>
    </v-col>
    <div
      v-if="!mouseOver || !$slots.actions"
      :class="textClass + ' text-center'"
      :style="[
        { fontSize: 0.08 * tileHeight + 'px' },
        { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
        { height: '40px' },
      ]"
    >
      {{
        (((currentPrice - firstPrice) / firstPrice) * 100)
          | currency({
            fractionCount: 2,
            symbol: "%",
            fractionSeparator: $store.getters.decimalSeparator,
          })
      }}
    </div>
    <v-card-actions
      v-else
      :style="[{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }, { height: '40px' }]"
    >
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>
<script>
import SvgLineChart from "../charts/SvgLineChart";

export default {
  props: {
    mouseOver: Boolean,
    tileId: String,
    title: String,
    ownedUnits: Number,
    tileHeight: Number,
    tileWidth: Number,

    dataPoints: {
      type: Array,
      default() {
        return [
          {
            x: 1500000800, // 0101
            y: 10,
          },
          {
            x: 1510000800, // idag
            y: 25,
          },
          {
            x: 1520000800, 
            y: 20,
          },
          {
            x: 1530000800,
            y: 30,
          },
        ];
      },
    },
    time: String,
  },
  watch: {
    dataPoints: function(newVal, oldVal) {

      if (
        newVal.length !== oldVal.length ||
        newVal[newVal.length - 1].y !== oldVal[oldVal.length - 1].y
      ) {
        this.animated = true;
      }
    },
  },
  data() {
    return {
      animated: false,
    };
  },
  methods: {},
  computed: {
    cardColor() {
      if (this.hasGainedValue) {
        return "positive";
      } else {
        return "negative";
      }
    },
    toolbarClass() {
      if (this.hasGainedValue) {
        return "positive black--text body-2";
      } else {
        return "negative black--text body-2";
      }
    },
    textClass() {
      return "font-weight-light";
    },
    hasGainedValue() {
      if (this.dataPoints && this.dataPoints.length) {
        return this.currentPrice >= this.firstPrice;
      }
      return false;
    },
    fractionCount() {
      if (this.currentPrice > 999) {
        return 0;
      } else if (this.currentPrice > 9) {
        return 2;
      } else {
        return 3;
      }
    },
    chartHeight() {
      if (this.tileHeight >= 260) {
        return Math.ceil(this.tileHeight / 2.2);
      } else if (this.tileHeight >= 160) {
        return Math.ceil(this.tileHeight / 2.8);
      } else {
        return Math.ceil(this.tileHeight / 4);
      }
    },
    priceHeight() {
      return this.tileHeight - this.chartHeight - 48 - 40;
    },
    firstPrice() {
      if (this.dataPoints && this.dataPoints.length) {
        return this.dataPoints[0].y;
      }
      return 0;
    },
    currentPrice() {
      if (this.dataPoints && this.dataPoints.length) {
        return this.dataPoints[this.dataPoints.length - 1].y;
      }
      return 0;
    },
  },
  components: {
    SvgLineChart,
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.zooming {
  animation: zoom 0.25s;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
</style>
