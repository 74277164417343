<template>
    <v-bottom-sheet
        v-model="sheet"
        :inset="$vuetify.breakpoint.mdAndUp"
        max-width="50em"
    >
        <v-card color="secondary">
            <!--height="60vh"-->
            <v-toolbar dark flat color="secondary darken-1">
                <!--darken-1-->
                <v-icon large></v-icon>
                <v-spacer></v-spacer>
                <v-toolbar-title centered>change visuals</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click.native="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-container fluid>
                <v-row wrap justify="space-around">
                    <v-col xs="12" sm="3">
                        <h2 class="body-2">theme</h2>
                        <v-select v-model="theme" :items="themes"> </v-select>
                    </v-col>
                    <v-col xs="12" sm="3">
                        <h2 class="body-2">decimal separator</h2>
                        <v-radio-group v-model="decimalSep">
                            <v-radio
                                color="primary"
                                label="dot"
                                value="."
                            ></v-radio>
                            <v-radio
                                color="primary"
                                label="comma"
                                value=","
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col xs="12" sm="3">
                        <h2 class="body-2">thousands separator</h2>
                        <v-radio-group v-model="thousandsSep">
                            <v-radio
                                color="primary"
                                label="space"
                                value=" "
                            ></v-radio>
                            <v-radio
                                color="primary"
                                label="comma"
                                value=","
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-bottom-sheet>
</template>
<script>
export default {
    computed: {
        theme: {
            get() {
                return this.$store.getters.theme;
            },
            set(val) {
                this.$store.commit("setTheme", val);
            },
        },
        themes() {
            return this.$store.state.themes.map((t) => t.name);
        },
        thousandsSep: {
            get() {
                return this.$store.getters.thousandsSeparator;
            },
            set(val) {
                this.$store.commit("setThousandsSeparator", val);
            },
        },
        decimalSep: {
            get() {
                return this.$store.getters.decimalSeparator;
            },
            set(val) {
                this.$store.commit("setDecimalSeparator", val);
            },
        },
        sheet: {
            get() {
                return this.$store.getters.showChangeVisualsDialog;
            },
            set(val) {
                this.$store.commit("setShowChangeVisualsDialog", val);
            },
        },
    },
    methods: {
        closeDialog() {
            this.$ga.event({
                eventCategory: "menu",
                eventAction: "close_change_visuals",
                //eventLabel: 'label',
                //eventValue: 123
            });

            this.$store.commit("setShowChangeVisualsDialog", false);
        },
    },
};
</script>
