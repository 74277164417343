<template>
  <span>
    <NoTiles v-if="noTiles"></NoTiles>
    <TileGrid v-else></TileGrid>
  </span>
</template>
<script>
import TileGrid from "../components/TileGrid.vue";
import NoTiles from "../components/NoTiles.vue";

import controller from "../controller";

export default {
  computed: {
    noTiles() {
      return this.$store.getters.tiles.length === 0;
    },
  },
  async mounted() {
    if (this.noTiles) {
      await this.$store.dispatch("getAvailableCoinsAndSaveToStore");
      await this.$store.dispatch("populateDashboardWithTopAvailableCoins");
    } else {
      this.$store.commit("deleteAllTickers");
      await this.$store.dispatch(
        "getHistoricalTickersForTilesInStoreAndWriteToStore"
      );
      await this.$store.dispatch(
        "getLatestTickersForTilesInStoreAndWriteToStore"
      );
    }

    controller.startTickerFetcher();
  },
  components: {
    NoTiles,
    TileGrid,
  },
};
</script>