import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import Vuetify from "../plugins/vuetify";

import { v4 as uuid } from "uuid";

import themes from "../themes.json";

Vue.use(Vuex);

import throttledApiClient from "../throttledApiClient";

let vuexPersistence = new VuexPersistence({
    reducer: (state) => ({
        storageVersion: state.storageVersion,
        tiles: state.tiles,
        assets: state.assets,
        visualSettings: state.visualSettings,
    }),
});

const initialState = {
    storageVersion: 2,
    storageHasBeenUpdatedSincePreviousVisit: false,
    tiles: [],
    timespans: [
        { name: "1h", numberOfTickers: 30, timespanSeconds: 60 * 60 },
        { name: "24h", numberOfTickers: 30, timespanSeconds: 24 * 60 * 60 },
        { name: "7d", numberOfTickers: 30, timespanSeconds: 7 * 86400 },
        { name: "30d", numberOfTickers: 30, timespanSeconds: 30 * 86400 },
        { name: "1y", numberOfTickers: 30, timespanSeconds: 365 * 86400 },
        { name: "2y", numberOfTickers: 30, timespanSeconds: 2 * 365 * 86400 },
        { name: "3y", numberOfTickers: 30, timespanSeconds: 3 * 365 * 86400 },
    ],
    assets: {
        lastUpdated: undefined,
        coins: [],
    },
    tick: {
        frequencyMs: 30 * 1000,
        progress: 0,
        progressIncrement: 100 / 30,
        progressIncrementFrequencyMs: 1000,
    },
    showAddTilesDialog: false,
    showChangeVisualsDialog: false,
    showShareDialog: false,
    rightDrawer: null,
    menuDrawer: false,
    snackbar: {
        show: false,
        text: "olol",
    },
    visualSettings: {
        theme: "default",
        decimalSeparator: ".",
        thousandsSeparator: " ",
    },
    themes: themes,
};

export default new Vuex.Store({
    plugins: [vuexPersistence.plugin],
    state: initialState,
    mutations: {
        resetState(state, storageVersion) {
            const s = initialState;
            Object.keys(s).forEach((key) => {
                state[key] = s[key];
            });

            if (storageVersion) {
                state.storageVersion = storageVersion;
            }
        },
        setStorageVersion(state, payload) {
            state.storageVersion = payload;
        },
        setStorageHasBeenUpdatedSincePreviousVisit(state, payload) {
            state.storageHasBeenUpdatedSincePreviousVisit = payload;
        },
        removeOldTickers(state) {
            console.log("running removeOldTickers");
            state.tiles
                .filter((tile) => tile.asset)
                .forEach((tile) => {
                    const timespan = state.timespans.find(
                        (timespan) => timespan.name === tile.timespanName
                    );
                    const tickersLengthOld = tile.tickers.length;

                    const movingStart =
                        Math.floor(Date.now() / 1000) -
                        timespan.timespanSeconds;

                    tile.tickers = tile.tickers.filter((ticker) => {
                        return new Date(ticker.timestamp) / 1000 > movingStart;
                    });
                    if (tickersLengthOld !== tile.tickers.length)
                        console.log(
                            "removed ticker because it's too old",
                            tickersLengthOld - tile.tickers.length
                        );
                });
        },
        addOrUpdateTicker(state, payload) {
            const tileIndex = state.tiles.findIndex((x) => x.i === payload.i);
            const tile = state.tiles[tileIndex];
            //console.log("tile.tickers.length", tile.tickers.length);
            const timespan = state.timespans.find(
                (timespan) => timespan.name === tile.timespanName
            );

            const lastTickerIndex = state.tiles[tileIndex].tickers.length - 1;
            const lastTicker = state.tiles[tileIndex].tickers[lastTickerIndex];

            const tickerErNyere =
                new Date(payload.ticker.timestamp).getTime() >
                new Date(lastTicker.timestamp).getTime();

            if (tickerErNyere) {
                const ticker = {
                    price: payload.ticker.price,
                    timestamp: new Date(payload.ticker.timestamp).getTime(),
                };

                if (tile.tickers.length >= timespan.numberOfTickers) {
                    state.tiles[tileIndex].tickers.pop();
                    state.tiles[tileIndex].tickers.push(ticker);
                } else {
                    state.tiles[tileIndex].tickers.push(ticker);
                }
            } else {
                // console.log("!tickerErNyere")
            }
        },
        setTickers(state, payload) {
            const tileIndex = state.tiles.findIndex((x) => x.i === payload.i);
            state.tiles[tileIndex].tickers = payload.tickers;
        },
        deleteAllTickers(state) {
            state.tiles.forEach(function(tile) {
                tile.tickers = [];
            });
        },
        setAvailableAssets(state, payload) {
            state.assets.coins = payload;
            state.assets.lastUpdated = Date.now();
        },
        setAssets(state, payload) {
            state.assets = payload;
        },
        setTiles(state, payload) {
            state.tiles = payload;
        },
        addTile(state, payload) {
            state.tiles.push(payload);
        },
        resizeTile(state, payload) {
            const tileIndex = state.tiles.findIndex((x) => x.i === payload.i);
            state.tiles[tileIndex].h = payload.h;
            state.tiles[tileIndex].w = payload.w;
        },
        moveTile(state, payload) {
            const tileIndex = state.tiles.findIndex((x) => x.i === payload.i);
            state.tiles[tileIndex].x = payload.x;
            state.tiles[tileIndex].y = payload.y;
        },
        deleteTile(state, payload) {
            const tileIndex = state.tiles.findIndex((x) => x.i === payload.i);
            state.tiles.splice(tileIndex, 1);
        },
        setShowAddTilesDialog(state, payload) {
            state.showAddTilesDialog = payload;
        },
        setShowChangeVisualsDialog(state, payload) {
            state.showChangeVisualsDialog = payload;
        },
        setShowShareDialog(state, payload) {
            state.showShareDialog = payload;
        },
        setRightDrawer(state, payload) {
            state.rightDrawer = payload;
        },
        toggleRightDrawer(state) {
            state.rightDrawer = !state.rightDrawer;
        },
        setMenuDrawer(state, payload) {
            state.menuDrawer = payload;
        },
        incrementTickProgress(state) {
            state.tick.progress =
                state.tick.progress + state.tick.progressIncrement;
        },
        resetTickProgress(state) {
            state.tick.progress = 0;
        },
        setSnackbar(state, payload) {
            state.snackbar.text = payload.text;
            state.snackbar.show = payload.show;
        },
        setTheme(state, payload) {
            const theme = state.themes.find((x) => x.name === payload) || themes[0];

            if (theme) {
                state.visualSettings.theme = payload;
                Vuetify.framework.theme.themes.dark.negative =
                    theme.negativeColor;
                Vuetify.framework.theme.themes.dark.positive =
                    theme.positiveColor;
                Vuetify.framework.theme.themes.dark.primary =
                    theme.primaryColor;
                Vuetify.framework.theme.themes.dark.secondary =
                    theme.secondaryColor;
            }
        },
        setThousandsSeparator(state, payload) {
            state.visualSettings.thousandsSeparator = payload;
        },
        setDecimalSeparator(state, payload) {
            state.visualSettings.decimalSeparator = payload;
        },
    },
    getters: {
        storageHasBeenUpdatedSincePreviousVisit: (state) => {
            return state.storageHasBeenUpdatedSincePreviousVisit;
        },
        storageVersion: (state) => {
            return state.storageVersion;
        },
        theme: (state) => {
            return state.visualSettings.theme;
        },
        decimalSeparator: (state) => {
            return state.visualSettings.decimalSeparator;
        },
        thousandsSeparator: (state) => {
            return state.visualSettings.thousandsSeparator;
        },
        timespans: (state) => {
            return state.timespans;
        },
        themes: (state) => {
            return state.themes;
        },
        assets: (state) => {
            return state.assets;
        },
        tiles: (state) => {
            return state.tiles;
        },
        assetTiles: (state) => {
            return state.tiles.filter((tile) => tile.asset);
        },
        rightDrawer: (state) => {
            return state.rightDrawer;
        },
        menuDrawer: (state) => {
            return state.menuDrawer;
        },
        showAddTilesDialog: (state) => {
            return state.showAddTilesDialog;
        },
        showChangeVisualsDialog: (state) => {
            return state.showChangeVisualsDialog;
        },
        showShareDialog: (state) => {
            return state.showShareDialog;
        },
        tickProgress: (state) => {
            return state.tick.progress;
        },
        tickFrequencyMs: (state) => {
            return state.tick.frequencyMs;
        },
        tickProgressIncrementFrequencyMs: (state) => {
            return state.tick.progressIncrementFrequencyMs;
        },
        snackbar: (state) => {
            return state.snackbar;
        },
    },
    actions: {
        addTileAndGetTickersAndSaveToStore(
            { commit, dispatch },
            { tile, timespan }
        ) {
            commit("addTile", tile);
            return dispatch("getHistoricalTickersForCoinAndSaveToStore", {
                tile,
                timespan,
            });
        },
        createTilesFromTopAvailableCoinsInStore({ commit, state }) {
            function buildInitialTiles(coins) {
                var tiles = [];

                coins.forEach((coin, coinIndex) => {
                    state.timespans
                        .filter((ts) => ts.name !== "7d" && ts.name !== "1h")
                        .forEach((timespan, timespanIndex) => {
                            tiles.push({
                                i: uuid(),
                                x: coinIndex, // timespan.name === "7d" ? coinIndex * 2 + 1 : coinIndex * 2,
                                y: timespanIndex,
                                w: 1, // timespan.name === "24h" || timespan.name === "7d" ? 1 : 2,
                                h: 1,
                                asset: {
                                    id: coin.id,
                                    title: coin.name,
                                    symbol: coin.symbol.toUpperCase() + "USD",
                                    image: coin.image.replace("large", "small"), // thumb
                                },
                                timespanName: timespan.name,
                                tickers: [],
                            });
                        });
                });
                return tiles;
            }

            const blacklistIds = [
                "tron",
                "tether",
                "bitcoin-cash-sv",
                "bitcoin-cash",
            ]; // "EOS", // eksponer evt som shitcoin-filter

            const topAvailableCoins = state.assets.coins
                .filter((x) => {
                    return blacklistIds.indexOf(x.id) === -1;
                })
                .slice(0, 6); // spør grid.js om antall?

            console.log("topAvailableCoins", topAvailableCoins);

            commit("setTiles", buildInitialTiles(topAvailableCoins));

            return Promise.resolve("success");
        },
        getAvailableCoinsAndSaveToStore({ commit }) {
            return new Promise((resolve, reject) => {
                throttledApiClient
                    .getAvailableCoins()
                    .then((res) => {
                        console.log("available assets", res);
                        commit("setAvailableAssets", res);
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        },
        getHistoricalTickersForTilesInStoreAndWriteToStore({
            state,
            dispatch,
        }) {
            function shuffleArray(array) {
                for (let i = array.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [array[i], array[j]] = [array[j], array[i]];
                }
            }

            let assetTiles = state.tiles.filter((tile) => tile.asset);
            shuffleArray(assetTiles);

            return Promise.all(
                assetTiles.map((tile) => {
                    const timespan = state.timespans.find(
                        (ts) => ts.name === tile.timespanName
                    );
                    return dispatch(
                        "getHistoricalTickersForCoinAndSaveToStore",
                        { tile, timespan }
                    );
                })
            );
        },
        getHistoricalTickersForCoinAndSaveToStore(
            { commit },
            { tile, timespan }
        ) {
            return new Promise((resolve, reject) => {
                throttledApiClient
                    .getHistoricalTickersForCoin(
                        tile.asset.id,
                        Math.floor(Date.now() / 1000) - timespan.timespanSeconds
                    )
                    .then((res) => {
                        commit("setTickers", { i: tile.i, tickers: res });
                        resolve();
                    })
                    .catch((err) => reject(err));
            });
        },
        getLatestTickersForTilesInStoreAndWriteToStore({ commit, state }) {
            const assetTiles = state.tiles.filter((tile) => tile.asset);
            const assetIdsWithDuplicates = assetTiles.map(
                (tile) => tile.asset.id
            );
            const uniqueAssetIdsInTiles = Array.from(
                new Set(assetIdsWithDuplicates)
            );

            return new Promise((resolve, reject) => {
                throttledApiClient
                    .getTickersForAllCoins()
                    .then((tickersForAllCoins) => {
                        const interessanteTickers = tickersForAllCoins.filter(
                            (ticker) =>
                                uniqueAssetIdsInTiles.indexOf(ticker.id) !== -1
                        );

                        interessanteTickers.forEach((ticker) => {
                            assetTiles
                                .filter((tile) => tile.asset.id === ticker.id)
                                .forEach((tile) =>
                                    commit("addOrUpdateTicker", {
                                        i: tile.i,
                                        ticker: {
                                            timestamp: ticker.last_updated,
                                            price: ticker.current_price,
                                        },
                                    })
                                );
                        });
                        resolve();
                    })
                    .catch((err) => reject(err));
            });
        },
        populateDashboardWithTopAvailableCoins({ dispatch }) {
            return dispatch("createTilesFromTopAvailableCoinsInStore").then(
                () => {
                    return dispatch(
                        "getHistoricalTickersForTilesInStoreAndWriteToStore"
                    ).then(() => {
                        return dispatch(
                            "getLatestTickersForTilesInStoreAndWriteToStore"
                        );
                    });
                }
            );
        },
    },
});
