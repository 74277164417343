var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"secondary lighten-1",attrs:{"mini-variant":true,"clipped":true,"app":"","mini-variant-width":"100"},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.lgAndUp)?{key:"append",fn:function(){return [_c('v-list',{attrs:{"nav":"","two-line":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.$store.commit('setRightDrawer', false)}}},[_c('v-list-item-action',{staticStyle:{"align-self":"unset"}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-list-item-content')],1)],1)]},proxy:true}:null],null,true),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":"","three-line":""}},[_c('NavigationTile'),_c('v-divider',{staticClass:"mx-3"}),_c('v-tooltip',{attrs:{"right":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"tile-cat-1",on:{"click":function($event){return _vm.openAddTiles()}}},on),[_c('v-list-item-action',{staticStyle:{"align-self":"unset"}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-list-item-content')],1)]}}])},[_c('span',[_vm._v("add tiles")])]),_c('v-tooltip',{attrs:{"right":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"tile-cat-1",on:{"click":function($event){return _vm.openChangeVisuals()}}},on),[_c('v-list-item-action',{staticStyle:{"align-self":"unset"}},[_c('v-icon',[_vm._v("mdi-palette")])],1),_c('v-list-item-content')],1)]}}])},[_c('span',[_vm._v("change visuals")])]),_c('v-tooltip',{attrs:{"right":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"tile-cat-1",on:{"click":function($event){return _vm.deleteTiles()}}},on),[_c('v-list-item-action',{staticStyle:{"align-self":"unset"}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-content')],1)]}}])},[_c('span',[_vm._v("delete all tiles")])]),_c('v-divider',{staticClass:"mx-3"}),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-tooltip',{attrs:{"right":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({on:{"click":function($event){return _vm.toggleFullscreen()}}},on),[_c('v-list-item-action',{staticStyle:{"align-self":"unset"}},[_c('v-icon',[_vm._v("mdi-fullscreen")])],1),_c('v-list-item-content')],1)]}}],null,false,3722889631)},[_c('span',[_vm._v("toggle fullscreen")])]):_vm._e(),_c('v-tooltip',{attrs:{"right":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({on:{"click":function($event){return _vm.share()}}},on),[_c('v-list-item-action',{staticStyle:{"align-self":"unset"}},[_c('v-icon',[_vm._v("mdi-share-variant")])],1),_c('v-list-item-content')],1)]}}])},[_c('span',[_vm._v("share")])]),_c('v-tooltip',{attrs:{"right":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({on:{"click":function($event){return _vm.resetApp()}}},on),[_c('v-list-item-action',{staticStyle:{"align-self":"unset"}},[_c('v-icon',[_vm._v("mdi-backup-restore")])],1),_c('v-list-item-content')],1)]}}])},[_c('span',[_vm._v("reset app")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }