<template>
  <v-list-item
    @mouseover.native="mouseOverr = true"
    @mouseleave.native="mouseOverr = false"
  >
    <v-list-item-action style="align-self: unset">
      <!--<v-icon v-if="mouseOverr" color="primary">mdi-view-dashboard</v-icon>-->
      <!--v-if="!mouseOverr"-->
      <v-progress-circular
        color="primary"
        size="25"
        width="2.5"
        :rotate="-90"
        :value="$store.getters.tickProgress"
      ></v-progress-circular>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
export default {
  data() {
    return { mouseOverr: false };
  },
  methods: {},
};
</script>
