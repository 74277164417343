<template>
  <v-card
    color="secondary"
    flat
    class="text-center pt-6"
    height="84vh"
    style="overflow: scroll"
  >
    <v-card-text>
      <v-autocomplete
        v-model="combo"
        class="ml-10 mr-10"
        outlined
        multiple
        deletable-chips
        hide-selected
        chips
        :items="$store.getters.assets.coins"
        item-text="name"
        return-object
        label="filter"
      ></v-autocomplete>
      <SearchResults :search="combo"></SearchResults>
    </v-card-text>
  </v-card>
</template>
<script>
import SearchResults from "./SearchResults";

export default {
  data() {
    return {
      combo: [
        {
          id: "bitcoin",
          symbol: "btc",
          name: "Bitcoin",
          image:
            "https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1547033579",
        },
        {
          id: "ethereum",
          symbol: "eth",
          name: "Ethereum",
          image:
            "https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880",
        },
        {
          id: "chainlink",
          symbol: "link",
          name: "ChainLink",
          image:
            "https://assets.coingecko.com/coins/images/877/large/chainlink-new-logo.png?1547034700",
        },
      ],
    };
  },
  methods: {},
  created() {},
  components: { SearchResults },
};
</script>
<style scoped>
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: #515151;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
